import { BrandDot } from '@/components/BrandDot'
import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { SEO } from '@/components/SEO'
import { StackedCards } from '@/components/StackedCards'
import { useTheme } from '@/store'
import {
  SingleProjectTemplateQuery,
  SingleProjectTemplateQueryVariables,
} from '@/types/__generated__/SingleProjectTemplateQuery'
import { fontSmoothing } from '@/utils/mixins'
import { fluidFontSize, scrimGradient } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import Masonry from 'react-masonry-css'

export const query = graphql`
  query SingleProjectTemplateQuery(
    $id: String!
    $nextId: String
    $prevId: String
  ) {
    contentfulProject(contentful_id: { eq: $id }) {
      name
      category {
        title
      }
      gallery {
        contentful_id
        fluid(maxWidth: 512, quality: 85) {
          ...ContentfulFluid
        }
        lightboxFluid: fluid(maxWidth: 1440, maxHeight: 1440, quality: 90) {
          src
        }
      }
    }
    prevProject: contentfulProject(contentful_id: { eq: $prevId }) {
      ...PreviousNextCardsFragment
    }
    nextProject: contentfulProject(contentful_id: { eq: $nextId }) {
      ...PreviousNextCardsFragment
    }
  }

  fragment PreviousNextCardsFragment on ContentfulProject {
    name
    slug
    coverImage {
      fluid(
        maxWidth: 480
        maxHeight: 270
        resizingBehavior: FILL
        quality: 85
      ) {
        ...ContentfulFluid
      }
    }
  }
`

export type PageContext = SingleProjectTemplateQueryVariables

type Props = {
  data: SingleProjectTemplateQuery
  pageContext: PageContext
}

const SingleProjectTemplate: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const { name, category, gallery } = data.contentfulProject
  const { nextProject, prevProject } = data
  const mqTwoCols = theme.mq.md
  const masonryBreakpoints = {
    default: 2,
    // 768 equals to mqTwoCols above
    767: 1,
  }
  const [isLightboxOpen, setLightboxOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const lightboxImages = gallery.map(({ lightboxFluid }) => lightboxFluid.src)

  return (
    <>
      <SEO
        title={name}
        metaDescription={`Gallery of our ${category.title.toLowerCase()} project: ${name}`}
      />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/portfolio/">Portfolio</BreadcrumbItem>
        <BreadcrumbItem href="/portfolio/projects/">Projects</BreadcrumbItem>
        <BreadcrumbItem>{name}</BreadcrumbItem>
      </Breadcrumb>

      <article>
        <div
          css={css`
            max-width: ${theme.maxWidth};
            margin: 0 auto;
            padding: 0 5%;
            box-sizing: content-box;
          `}
        >
          <header
            css={css`
              margin-top: 2em;
              margin-bottom: 2em;
              color: ${theme.color.text_default};
              ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
              text-align: center;

              .title {
                font-size: 1em;
                color: ${theme.color.text_default};
                letter-spacing: -0.03em;
              }

              .category {
                margin-top: 1rem;

                color: ${theme.color.text_lighter};
                ${fluidFontSize(14, 16, 320, theme.vwImageCapWidth)};
                text-transform: uppercase;
                font-style: italic;
                letter-spacing: 0.03em;
              }
            `}
          >
            <h1 className="title">
              {name}
              <BrandDot />
            </h1>
            <p className="category">{category.title} Project</p>
          </header>

          <Masonry
            breakpointCols={masonryBreakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {gallery.map(({ fluid, contentful_id }, index) => (
              <div
                key={contentful_id}
                css={css`
                  position: relative;
                  border-radius: ${theme.radius.photo};
                  box-shadow: ${theme.elevation[2]};
                  overflow: hidden;
                `}
              >
                <Img fluid={fluid} alt="" />
                <button
                  type="button"
                  onClick={() => {
                    setLightboxOpen(true)
                    setPhotoIndex(index)
                  }}
                  css={css`
                    cursor: zoom-in;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    appearance: none;
                    background: none;
                    border: none;
                  `}
                />
              </div>
            ))}
          </Masonry>
        </div>

        <footer
          css={css`
            margin-top: 6rem;
            background: ${theme.color.bg_darker};
            box-shadow: inset 0 2px 8px 0 rgba(${theme.color.shadow}, 0.1);
            padding: 4rem 5%;
          `}
        >
          <div
            css={css`
              max-width: ${theme.maxWidth};
              margin: 0 auto;

              .prev-wrapper {
                margin-top: 2rem;
              }

              .scrim {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 3.125%;
                padding-top: 12.5%;

                ${scrimGradient({
                  color: theme.color.grays[8],
                  direction: 'to top',
                  startAlpha: 0.64,
                })}
              }

              .next-text,
              .prev-text,
              .next-title,
              .prev-title {
                position: relative;
                color: ${theme.color.grays[0]};
                ${fontSmoothing};
                text-shadow: ${theme.textShadow};
              }

              .next-text,
              .prev-text {
                font-size: ${theme.size[1]};
                text-transform: uppercase;
                letter-spacing: 0.03em;
              }

              .next-title,
              .prev-title {
                font-size: ${theme.size[3]};
                white-space: nowrap;
                overflow-x: hidden;
                /* line-height is only there because descender is clipped by overflow hidden */
                line-height: 1.3;
                text-overflow: ellipsis;
              }

              ${mqTwoCols} {
                display: flex;
                flex-direction: row-reverse;

                .next-wrapper,
                .prev-wrapper {
                  width: 50%;
                }

                .next-wrapper {
                  padding-left: 2rem;
                  margin-left: auto;
                }

                .prev-wrapper {
                  margin-top: 0;

                  padding-right: 2rem;
                  margin-right: auto;
                }

                .prev-text,
                .prev-title {
                  text-align: right;
                }
              }
            `}
          >
            {nextProject && (
              <article className="next-wrapper">
                <Link to={`/portfolio/projects/${nextProject.slug}/`}>
                  <StackedCards>
                    <>
                      <Img fluid={nextProject.coverImage.fluid} alt="" />
                      <div className="scrim">
                        <p className="next-text">Next</p>
                        <h2 className="next-title">{nextProject.name}</h2>
                      </div>
                    </>
                  </StackedCards>
                </Link>
              </article>
            )}

            {prevProject && (
              <article className="prev-wrapper">
                <Link to={`/portfolio/projects/${prevProject.slug}/`}>
                  <StackedCards>
                    <>
                      <Img fluid={prevProject.coverImage.fluid} alt="" />
                      <div className="scrim">
                        <p className="prev-text">Previous</p>
                        <h2 className="prev-title">{prevProject.name}</h2>
                      </div>
                    </>
                  </StackedCards>
                </Link>
              </article>
            )}
          </div>
        </footer>
      </article>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length
            ]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightboxImages.length)
          }
        />
      )}
    </>
  )
}

export default SingleProjectTemplate
